.config-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.buttons {
  margin-bottom: 20px;
  position: relative; /* 为悬浮提示设置相对定位上下文 */
}

.btn {
  background-color: #007bff; /* Bootstrap primary color */
  color: white;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.yaml-display {
  width: 90%; /* Adjust to your preference */
  height: 400px; /* Adjust to your preference */
  background-color: #f8f9fa; /* Light gray background */
  border: 1px solid #ced4da; /* Bootstrap gray border */
  border-radius: 5px;
  padding: 10px;
  font-family: 'Courier New', Courier, monospace; /* Monospace font for code */
  white-space: pre; /* Keeps the formatting for white spaces and new lines */
  overflow: auto; /* Adds scrollbars if necessary */
}
.copy-button-container {
  position: relative;
  display: inline-block; /* 包裹按钮和提示消息 */
}

.copy-success-message {
  position: absolute;
  top: 0;
  left: 110%; /* 将提示消息定位到按钮的右侧 */
  background-color: #dff0d8;
  color: #3c763d;
  padding: 8px 15px;
  border-radius: 4px;
  white-space: nowrap; /* 防止文本换行 */
  z-index: 1000; /* 确保提示消息显示在其他元素之上 */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .yaml-display {
    width: 100%;
  }
}
