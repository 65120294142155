.dashboard-container {
  display: flex;
  height: 100vh;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.sidebar {
  flex: 0 0 220px; /* Adjust the width of the sidebar */
  background-color: #34495e; /* 灰蓝色背景 */
  color: #ecf0f1; /* 浅灰色文本 */
  display: flex;
  flex-direction: column;
  gap: 10px; /* 在功能之间添加一点间隔 */
  padding: 20px; /* 增加一些内边距 */
}

.logo {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 20px; /* 增加LOGO与菜单项的距离 */
}

.menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px; /* 菜单项之间的间隔 */
}

.menu li button {
  width: 100%;
  padding: 15px; /* 增加按钮的填充 */
  border: none;
  background-color: #2980b9; /* 蓝色按钮 */
  color: white;
  cursor: pointer;
  border-radius: 4px; /* 添加圆角 */
  transition: background-color 0.3s; /* 平滑过渡效果 */
}

.menu li button:hover {
  background-color: #3498db; /* 鼠标悬浮时的颜色 */
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #ecf0f1; /* 浅灰色背景 */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #bdc3c7; /* 深灰色头部 */
  border-bottom: 2px solid #95a5a6; /* 加深的边框线 */
}

.main-content {
  flex: 1;
  padding: 20px; /* 主内容区域的内边距 */
}
