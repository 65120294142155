html,
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden; /* To prevent vertical scrolling */
  background-color: #2a2d3e;
}

#app {
  min-height: 100%;
  overflow: hidden; /* Hide overflow both horizontally and vertically */
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Ensures at least full viewport height */
  width: 100vw;
  overflow: hidden; /* Hide overflow both horizontally and vertically */
  margin: 0;
}

.login-form {
  padding: 2rem;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.5);
  width: 300px;
}

.form-title {
  text-align: center;
  margin-bottom: 2rem;
  color: #333;
}

.input-group {
  margin-bottom: 1rem;
}

.input-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.input-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.login-button {
  width: 100%;
  padding: 0.5rem;
  border: none;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-button:hover,
.login-button:disabled {
  background-color: #367c39;
}

.error-message {
  color: #e74c3c;
  text-align: center;
  margin-top: 1rem;
}
